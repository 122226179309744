import { useEffect, useState, useRef } from 'react';
import { Dimensions, View, StyleSheet, Text, StatusBar } from 'react-native';
import { Video } from 'expo-av';
import * as ScreenOrientation from 'expo-screen-orientation';

import LoadingScreen from './LoadingScreen.jsx';
import VideoWebView from './components/VideoWebView.jsx'

import { VIDEOS } from '../constants/videos.js';

export default function VideoScreen({navigation, route}) {
  const video = useRef(null);
  const name = route.params.name;
  // const name = 'bike'
  const [status, setStatus] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDone, setIsDone] = useState(false);
  // const { width, height } = Dimensions.get("window");
  

  const loadVideo = async () => {
    await video?.current?.loadAsync({
      uri: VIDEOS[name],
    });

    // await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)

    setIsLoaded(true);
  }

  useEffect(() => {
    loadVideo()
  }, []);

  const onFullscreenPressed = () => {
    try {
      video.current.presentFullscreenPlayer();
    } catch (error) {
      console.log(error.toString());
    }
  };

  const onPlaybackStatusUpdate = (status) => {
    setStatus(() => status)

    if (status?.didJustFinish) {
      setIsDone(true)
      
      setTimeout(() => {
        // ScreenOrientation.unlockAsync().then(() =>
        navigation.navigate('Icon')
        // );
      }, 5000)
    }
  };

  if (!isLoaded) {
    return <LoadingScreen />
  }

  if (isDone) {
    return <View style={{...styles.container, justifyContent: 'center'}}>
      <Text style={{letterSpacing: 1, fontSize: 24, textAlign: 'center', color: 'white'}}>Thank you for waiting.</Text>
    </View>
  }
   return (
    <VideoWebView>
      <View style={styles.container}>
          <StatusBar backgroundColor="#b3e6ff" barStyle="light-content" />  
              <View>  
                  <StatusBar hidden={true} />  
              </View>  
        <Video
          onFullscreenUpdate={onFullscreenPressed}
          onPlaybackStatusUpdate={onPlaybackStatusUpdate}
          ref={video}
          resizeMode="contain"
          style={{width: 640, height: 340}}
          source={{
            uri: VIDEOS[name],
          }}
          shouldPlay
          // videoStyle={{ width, height }}
          videoStyle={{width: 640, height: 340, top: 17 , left: 5, borderRadius: 8}}
          volume={1.0}
        />
      </View>
    </VideoWebView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    position: 'relative',
    borderRadius: 8,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  button: {
    height: 100,
    width: 100,
    backgroundColor: 'white'
  }
}); 
