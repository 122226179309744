import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFonts } from 'expo-font';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as SystemUI from 'expo-system-ui';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

import HomeScreen from './screens/HomeScreen.jsx';
import IconScreen from './screens/IconScreen.jsx';
import VideoScreen from './screens/VideoScreen.jsx';

import { ICONS } from './constants/icons.js';

const Stack = createNativeStackNavigator();

// function setAppLaunched() {
//   AsyncStorage.setItem('hasLaunched', JSON.stringify('Yes.'));
// }

function cacheImages(images) {
  return images.map(image => Image.prefetch(ICONS[image]));
}

// Notifications.setNotificationHandler({
//   handleNotification: async () => ({
//     shouldShowAlert: true,
//     shouldPlaySound: true,
//     shouldSetBadge: true,
//   }),
// });

// async function registerForPushNotificationsAsync() {
//   let token;
//   if (Device.isDevice) {
//     const { status: existingStatus } = await Notifications.getPermissionsAsync();
//     let finalStatus = existingStatus;
//     if (existingStatus !== 'granted') {
//       const { status } = await Notifications.requestPermissionsAsync();
//       finalStatus = status;
//     }
//     if (finalStatus !== 'granted') {
//       alert('Failed to get push token for push notification!');
//       return;
//     }
//     token = (await Notifications.getExpoPushTokenAsync()).data;
//     console.log(token);
//   } else {
//     alert('Must use physical device for Push Notifications');
//   }

//   if (Platform.OS === 'android') {
//     Notifications.setNotificationChannelAsync('default', {
//       name: 'default',
//       importance: Notifications.AndroidImportance.MAX,
//       vibrationPattern: [0, 250, 250, 250],
//       lightColor: '#FF231F7C',
//     });
//   }

//   return token;
// }


export default function App() {
  const [fontsLoaded] = useFonts({
    'mont': require('./assets/Montserrat-ExtraLight.ttf'),
    'ave': require('./assets/AvenirLTStd-Black.otf'),
    'italic': require('./assets/AvenirNextLTProItalic.otf')
  });
  const [appIsReady, setAppIsReady] = useState(false)

  // NOTIFICATIONS
  // const [expoPushToken, setExpoPushToken] = useState('');
  // const [notification, setNotification] = useState(false);
  // const notificationListener = useRef();
  // const responseListener = useRef();

  // const checkIfFirstLaunch = async () => {
  //   const hasLaunched = await AsyncStorage.getItem('hasLaunched');
  //   if (!hasLaunched) {
  //     alert('Please turn on push notifications for newly uploaded performances')
  //     setAppLaunched()
  //   } 
  // }

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      let images
      let backgroundColor

      try {
        images = cacheImages(Object.keys(ICONS));
        // const orientation = ScreenOrientation.unlockAsync();
        backgroundColor = SystemUI.setBackgroundColorAsync("black");

        await Promise.all([...images, fontsLoaded, backgroundColor]);
      } catch (e) {
         console.warn(e);
      } finally {
        setTimeout(() => setAppIsReady(true), 1500)
      }
    }

    // NOTIFICATIONS
    // registerForPushNotificationsAsync().then(token => {
    //   setExpoPushToken(token)

    //   setTimeout(() => checkIfFirstLaunch(), 7500)
    // });

    // notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
    //   console.log('notification', notification)
    //   setNotification(notification);
    // });

    // responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
    //   console.log('response', response)
    //   console.log(response);
    // });
 

    loadResourcesAndDataAsync();
    // return () => {
    //   Notifications.removeNotificationSubscription(notificationListener.current);
    //   Notifications.removeNotificationSubscription(responseListener.current);
    // }
  }, []);


  if (!fontsLoaded && !appIsReady) {
    return null
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer theme={{ colors: { background: 'transparent' } }}>
        <Stack.Navigator>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{
              headerShown: false,
              cardStyle: { backgroundColor: 'transparent' },
            }}
          />
          <Stack.Screen
            name="Icon"
            component={IconScreen}
            options={{
              headerShown: false,
              cardStyle: { backgroundColor: '#000' },
            }}
          />
          <Stack.Screen
            name="Video"
            component={VideoScreen}
            options={{
              headerShown: false
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}

