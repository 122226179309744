export const ICONS = {
  compost: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655213/Compost_krgswx.jpg',
  stewing: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655213/Stewing_ghbrei.jpg',
  cleaning: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655213/cleaning_products_belxrm.jpg',
  dancing: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655213/Dancing_hjfayz.jpg',
  chiminea: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655213/Chiminea_ogmci7.jpg',
  bike: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655213/Bike_zxwteh.jpg',
  garbage: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655214/Garbage_zrsxkx.jpg',
  cannedGoods: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655214/Canned_Goods_dcobip.jpg',
  toiletPaper: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655214/Toilet_Paper_xmbr1v.jpg',
  groceries: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655214/Groceries_fii3su.jpg',
  wine: 'https://res.cloudinary.com/doiblpfax/image/upload/v1673655214/Wine_mjr4hg.jpg',
}
