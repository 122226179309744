import React from "react";
import { Image, SafeAreaView, StyleSheet, TouchableHighlight,  View } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';

import WebView from './components/WebView.jsx';

import { ICONS } from '../constants/icons.js';

export default function IconScreen({navigation}) {
  return <WebView>
  <View style={styles.container}>
    
      <TouchableHighlight
        onPress={() => navigation.navigate('Home')}
        style={{ width: '100%', textAlign: 'left', paddingLeft: 10, marginBottom: 20}}
        accessible={true}
        accessibilityLabel="Tap here to go back to the Home screen"
      >
        <Ionicons name="arrow-back-circle-outline" style={{ fontSize: 36, color: 'white'}} />
      </TouchableHighlight>
      <View style={styles.content}>
        {Object.keys(ICONS).map((icon, index) =>
          <View style={styles.column} key={icon}>
            <TouchableHighlight
              accessible={true}
              accessibilityLabel={`Tape here to watch the ${icon} video`}
              activeOpacity={0.6}
              underlayColor="#DDDDDD"
              onPress={() => navigation.navigate("Video", {name: icon})}
              style={styles.button}
            >
              <Image
                style={styles.image}
                source={{ uri: ICONS[icon] }}
              />
            </TouchableHighlight>
          </View>
        )}
      </View>
    </View>
  </WebView>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "black",
    color: "white",
  },
  content: {
    flexWrap: 'wrap',
    alignContent: 'space-around',
    height: '80%',
    width: '60%',
    backgroundColor: "black",
  },
  column: {
    marginBottom: 15,
    marginRight: 20,
    alignItems: 'center',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
  },
  image: {
    backgroundSize: 'contain',
    borderRadius: 50,
    resizeMode: 'contain',
    height: 80,
    width: 80,
  },
});


