import React, { useEffect, useRef } from 'react';
import { Animated, SafeAreaView, StyleSheet, Text, View } from 'react-native';
import WebView from './components/WebView.jsx';


export default function LoadingScreen() {
    const FadeInText = (props) => {
        const sizeAnimation = useRef(new Animated.Value(85)).current  // Initial value for opacity: 0

        React.useEffect(() => {
            Animated.timing(
                sizeAnimation,
                {
                    toValue: 0,
                    duration: 5000,
                    useNativeDriver: false,
                }
            ).start()
        }, [sizeAnimation])

        return (
            <SafeAreaView style={{ marginBottom: props.children === 'waiting' ? 0 : 30, }}>
                <Animated.Text
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        color: '#404040',
                        overflow: 'hidden',
                        height: sizeAnimation,
                        fontSize: 48,
                        fontFamily: 'mont',
                        zIndex: 1,
                    }}
                    useNativeDriver={false}
                >
                    {props.children}
                </Animated.Text>
                <Text
                    style={{
                        position: 'relative',
                        color: 'white',
                        fontSize: 48,
                        fontFamily: 'mont',
                        paddingBottom: props.children === 'waiting' ? 0 : 30,
                        borderBottom: props.children === 'waiting' ? 'none' : '1px solid white',
                        zIndex: 0
                    }}
                >
                    {props.children}
                </Text>

            </SafeAreaView>
        );
    }

    return <WebView>
        <View style={styles.container}>
            <FadeInText text="x">x</FadeInText>
            <FadeInText>in</FadeInText>
            <FadeInText>waiting</FadeInText>
        </View>
    </WebView>;
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 30,
        // backgroundColor: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    }
});
