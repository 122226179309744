export const VIDEOS = {
  compost: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691195/xinwaiting_videos_final/Yard_Waste_Final_1_avklpk.mp4',
  stewing: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691179/xinwaiting_videos_final/Stewing_Final_1_er0djl.mp4',
  cleaning: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691175/xinwaiting_videos_final/Cleaning_Products_Final_1_acov2j.mp4',
  dancing: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691199/xinwaiting_videos_final/Dancing_Final_1_k4x5dm.mp4',
  chiminea: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691150/xinwaiting_videos_final/Chiminea_Final_1_ygse0j.mp4',
  bike: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691184/xinwaiting_videos_final/Bike_Final_1_jyynv8.mp4',
  garbage: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691174/xinwaiting_videos_final/Garbage_Final_1_qgbwuc.mp4',
  cannedGoods: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691190/xinwaiting_videos_final/Canned_Goods_Final_1_gtaywa.mp4',
  toiletPaper: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691196/xinwaiting_videos_final/Toilet_Paper_Final_1_ewrzb5.mp4',
  groceries: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691165/xinwaiting_videos_final/Groceries_Final_1_adlbb0.mp4',
  wine: 'https://res.cloudinary.com/doiblpfax/video/upload/v1676691199/xinwaiting_videos_final/Wine_Final_1_h1mvub.mp4',
}

