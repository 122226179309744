import { StyleSheet, View } from 'react-native';

export default function VideoWebView({ children }) {
  return <View style={styles.wrapper}>
    <View style={styles.container}>
      {children}
    </View>
  </View>
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: 'black',
    justifyContent: 'center',
    // paddingTop: 20,
    // paddingRight: 30,
    // paddingBottom: 20,
    // paddingLeft: 30,
    width: 650,
    height: 360,
    borderRadius: 8,
    boxShadow: '0px 0px 10px 0px rgba(220,220,220,0.7)',
  }
})
