import React, { useState, useEffect, useRef } from 'react';
import { Animated, Image, ScrollView, StyleSheet, View, Text, TouchableHighlight } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import LoadingScreen from './LoadingScreen.jsx';
import WebView from './components/WebView.jsx';

import { LINKS } from '../constants/links.js';

export default function HomeScreen({navigation}) {
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        setTimeout(setShowLoading, 5000);
    }, [])

    const FadeInView = props => {
        const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

        useEffect(() => {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 5000,
                useNativeDriver: true,
            }).start();
        }, [fadeAnim]);

        return (
            <Animated.View // Special animatable View
                style={{
                    ...props.style,
                    opacity: fadeAnim, // Bind opacity to animated value
                }}
                useNativeDriver={true}
                >
                {props.children}
            </Animated.View>
        );
    };

    if (showLoading) {
        return <LoadingScreen />
    }

    return <View style={styles.container}>
        <SafeAreaView>
            <WebView>
                <FadeInView>
                    <Text style={styles.title}>
                        Welcome to x-in-waiting
                    </Text>
                </FadeInView>
                
                <ScrollView
                    style={styles.scrollHeight}
                >
                    <FadeInView>
                        <Text style={styles.text}>
                            x-in-waiting refers to the period of stasis that occurs for something (the variable ‘x’) to
                            become something else. For this work the “x” stands in for whatever occupation the artist is
                            consumed with while being ‘in waiting’.
                        </Text>
                        <Text style={styles.text}>
                            This is a performance art meditation app that delivers performances directly to you.
                            This series of performances started during the COVID-19 pandemic out of a need to
                            “do something:” to force creativity in order to alleviate the crushing anxiety due to
                            the state of the world; and to pass the time while we were all sequestered into self-
                            isolation.
                        </Text>
                        <Text style={styles.text}>
                            This app is a way to engage folks with performance in a different way since being in close
                            proximity is still not as comfortable as it once was. This work allows the viewer to take the
                            work away with them and engage with it on their own terms (on a bus, at a park, in a
                            shopping mall). The work also echoes the reality of isolation and humorously creates
                            moments of performance from everyday common items and situations, which may mirror
                            the audience&#39;s experience through this pandemic. In a way, this work plays with the idea of an individual’s experience of witnessing a performance created for one.
                        </Text>
                        <Text style={styles.text}>
                            <Text style={{ fontFamily: 'italic', color: 'white' }}>x-in-waiting</Text> asks viewers to take eight minutes out of their day to pause and wait, perform, and watch with the artist.
                        </Text>
                        <Text style={styles.text}>
                            Challenge your attention span and wait with the artist
                        </Text>
                        <Text style={styles.text}>
                            Push notifications will alert you when a new performance meditation is uploaded.
                        </Text>
                        <Text style={styles.text}>
                            This app will self-destruct when the waiting is over.
                        </Text>
                        <Text style={{ ...styles.text, textAlign: 'center' }}>
                            By David Yu
                        </Text>
                    </FadeInView>
                </ScrollView>
                
                <FadeInView>
                    <View style={styles.buttonContainer}>
                        <TouchableHighlight
                            accessible={true}
                            accessibilityLabel="Tap me to go to the video selection screen"
                            activeOpacity={0.6}
                            underlayColor="#DDDDDD"
                            onPress={() => navigation.navigate('Icon')}
                            style={{ borderRadius: 50 }}
                        >
                            <Image
                                source={{ uri: LINKS.enter }}
                                style={{ ...styles.image, width: 100, height: 100 }}
                            />
                        </TouchableHighlight>

                    </View>

                    <View style={styles.iconContainer}>
                        <Image
                            source={{ uri: LINKS.cca }}
                            style={styles.logo}
                        />
                        <Image
                            source={{ uri: LINKS.lomaa }}
                            style={styles.logo}
                        />
                        <Image
                            source={{ uri: LINKS.oac }}
                            style={styles.logo}
                        />
                        <Image
                            source={{ uri: LINKS.agp }}
                            style={{ ...styles.logo, height: 60 }}
                        />
                    </View>

                    <Text style={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: 6, textAlign: 'center', marginRight: 0 }}>Developed By: Tory Shoreman</Text>
                </FadeInView>
            </WebView>
        </SafeAreaView>
    </View>;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingRight: 30,
        paddingLeft: 30,
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'rgba(255, 255, 255, 0.8)',
        fontFamily: 'ave',
        fontSize: 18,
        textAlign: 'center',
    },
    scrollHeight: {
        height: '80%',
        overflowY: 'scroll',
    },
    buttonContainer: {
        marginTop: 10,
        alignItems: 'center',
    },
    iconContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 10,
    },
    text: {
        marginBottom: 10,
        fontSize: 14,
        color: 'rgba(255, 255, 255, 0.8)',
        fontFamily: 'ave',
        fontWeight: 'normal',
        textAlign: 'justify',
    },
    title: {
        marginBottom: 20,
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 20,
        fontFamily: 'ave',
        fontWeight: 'bold',
        textAlign: 'center'  ,
    },
    image: {
        backgroundSize: 'contain',
        resizeMode: 'contain',
        width: 100,
        height: 75,
        borderRadius: 50,
    },
    logo: {
        backgroundSize: 'contain',
        resizeMode: 'contain',
        width: '20%',
        height: 75,
        marginRight: 10,
    }
});
